
.hoverEffect:hover {
    border: 3px;
    border-color: white;
    border-style: dotted;
  }

  .hoverEffectzIndex {
    border: 3px;
    border-color: white;
    border-style: dotted;
  }
