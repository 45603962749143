
  

  .marquee {
    margin: 0 auto;
    white-space: nowrap;
    overflow: hidden;
    position: absolute;
    width: 100%;
  }
  
  .marquee span {
    display: inline-block;
    padding-left: 100%;
    animation: marquee linear infinite;
  }
  
  .marquee2 span {
    animation-delay: 2.5s;
  }
  
  @keyframes marquee {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(-100%, 0);
    }
  }