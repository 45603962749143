.appContainer {
    text-align: center;
    margin: 20px;
}
 
h1 {
    font-size: 2.5rem;
    margin-bottom: 20px;
    color: #05ca40ce;
}
 

 
.calendar-container {
    flex: 1;
    max-width: 500px;
    margin-right: 2rem;
}
 
.event-container {
    flex: 1;
    max-width: 500px;
}

.react-calendar {
    width: 100%;
    max-width: 500px;
    background: rgba(255, 255, 255, 0.9);
    border: 1px solid #ccc;
    font-family: Arial, Helvetica, sans-serif;
    line-height: 1.125em;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 10px;
    transition: background-color 0.2s;
}
 
.react-calendar--doubleView {
    width: 100%;
}
 
.react-calendar--doubleView .react-calendar__viewContainer {
    display: flex;
    margin: -0.5em;
}
 
.react-calendar--doubleView .react-calendar__viewContainer>* {
    width: 50%;
    margin: 0.5em;
}
 
.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
 
.react-calendar button {
    margin: 0;
    border: 0;
    outline: none;
}
 
.react-calendar button:enabled:hover {
    cursor: pointer;
}
 
.react-calendar__navigation {
    display: flex;
    height: 44px;
    margin-bottom: 1em;
}
 
.react-calendar__navigation button {
    min-width: 44px;
    background: none;
}
 
.react-calendar__navigation button:disabled {
    background-color: #f0f0f0;
}
 
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
    background-color: #e6e6e6;
}
 
.react-calendar__month-view__weekdays {
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.8rem;
}
 
.react-calendar__month-view__weekdays__weekday {
    padding: 0.5em;
}
 
.react-calendar__month-view__weekNumbers .react-calendar__tile {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.75em;
    font-weight: bold;
}
 
.react-calendar__month-view__days__day--weekend {
    color: #d10000;
}
 
.react-calendar__month-view__days__day--neighboringMonth {
    color: #757575;
}
 
.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
    padding: 2em 0.5em;
}
 
.react-calendar__tile {
    max-width: 100%;
    padding: 10px 6.6667px;
    background: none;
    text-align: center;
    line-height: 16px;
}
 
.react-calendar__tile:disabled {
    background-color: #f0f0f0;
}
 
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
    background-color: #e6e6e6;
}
 
.react-calendar__tile--now {
    background: #66e888;
}
 
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
    background: #aaa9ff;
}
 
.react-calendar__tile--hasActive {
    background: #76baff;
}
 
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
    background: #a9d4ff;
}
 
.react-calendar__tile--active {
    background: #007bff;
    color: white;
}
 
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
    background: #0056b3;
}
 
.react-calendar--selectRange .react-calendar__tile--hover {
    background-color: #e6e6e6;
}
 
.event-form {
    margin-bottom: 20px;
}
 
.event-form h2 {
    margin-bottom: 10px;
    color: #007bff;
}
 
.event-form p {
    font-size: 1.2rem;
    color: #1403f6;
}
 
.event-form input {
    padding: 8px;
    font-size: 1rem;
    margin-right: 10px;
}
 
.create-btn {
    padding: 8px 16px;
    background-color: #ff4081;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.2s;
}
 
.create-btn:hover {
    background-color: #ff267f;
}
 
.event-list {
    margin-top: 20px;
}
 
.event-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
 
.event-card {
    width: 300px;
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 8px;
    margin: 10px;
    padding: 10px;
    cursor: pointer;
    transition: transform 0.2s, box-shadow 0.2s;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
 
.event-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
 
.event-card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}
 
.event-date {
    font-size: 1rem;
    color: #f07809;
}
 
.event-actions {
    display: flex;
}
 
.update-btn,
.delete-btn {
    padding: 8px 12px;
    margin-left: 5px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 0.9rem;
    cursor: pointer;
    transition: background-color 0.2s;
}
 
.update-btn:hover,
.delete-btn:hover {
    background-color: #0056b3;
}
 
.event-card-body {
    padding-bottom: 10px;
}
 
.event-title {
    font-size: 1.5rem;
    color: #fd0000;
}
 
.selected {
    background-color: #4079ff !important;
    color: white;
}
 
.event-marked {
    background-color:#fa600d;
    color: white;
}

.calendar-container {
    display: flex;
    align-items: flex-start;
  }
  
  .date-list {
    width: 200px;
    padding: 10px;
    border-right: 1px solid #ccc; 
  }
  
  .date-list ul {
    list-style-type: none;
    padding: 0;
  }
  
  .calendar {
    flex: 1;
    margin-left: 20px; 
  }
  

  .scroll::-webkit-scrollbar {
    width: 12px;
  }
  
  .scroll::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 30px  6px rgba(245, 240, 240, 0.27);
    border-radius: 10px;
  }
  
  .scroll::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 30px  6px rgba(245, 240, 240, 0.27);
  }
  
  